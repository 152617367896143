import "./App.css";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import AOS from "aos";

function App() {

  AOS.init()

  return (
    <div className="App">
      <div className='bg-[url("./assets/bg.jpg")] h-screen w-screen bg-no-repeat bg-cover'>
        <Navbar />
        <Header />
      </div>
    </div>
  );
}

export default App;
