import React from "react";
import screens from "../assets/screens.png";

export default function Header() {
  return (
    <div className="flex flex-col md:flex-row md:h-screen justify-center items-center gap-6 p-4 md:p-0">
      <div
        className="text-left flex flex-col justify-center"
        data-aos="fade-up"
        data-aos-delay="400"
        data-aos-duration="200"
        data-aos-easing="ease-in-out"
      >
        <h1 className="text-[40px] md:text-[68px] font-medium text-white m-0 p-0 leading-none">
          El poder de
        </h1>
        <h1 className="text-[60px] md:text-[98px] font-black text-[#D83C35] m-0 p-0 leading-none">
          la Música
        </h1>
        <h1 className="text-[40px] md:text-[68px] font-medium text-white m-0 p-0 leading-none">
          en tus manos
        </h1>
      </div>
      <div
        className="md:w-1/2"
        data-aos="fade-up"
        data-aos-delay="500"
        data-aos-duration="200"
        data-aos-easing="ease-in-out"
      >
        <img src={screens} alt="" />
      </div>
    </div>
  );
}
