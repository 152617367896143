import React from "react";
import logo from "../assets/logo.png";

export default function Navbar() {
  return (
    <div className="flex w-full justify-between flex-col md:flex-row items-center py-6 px-10 md:fixed gap-6 md:gap-0">
      <div className="w-[160px]">
        <img src={logo} alt="" />
      </div>
      <div className="flex flex-col md:flex-row gap-6 justify-center items-center">
        <div className="text-[16px] md:text-[18px] text-white font-medium">¿Te dedicas al mundo de la música?</div>
        <div className="text-[16px] md:text-[18px] bg-[#D83C35] py-2 px-4 md:py-4 md:px-6 rounded-full text-white font-bold hover:bg-[#B3322C] cursor-pointer transition-all ease-in-out">
          Conoce los beneficios
        </div>
      </div>
    </div>
  );
}
